<template>
  <div>
    <div class="row">
      <div class="col-9"></div>
      <div class="col-3">
        <div class="d-flex align-items-center search-input mb-4">
          <input
            placeholder="Ara.."
            v-model="search"
            class="form-control mr-2"
          />
          <select v-model="list_length" class="form-control ml-2">
            <option value="" selected>Seçiniz</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
    </div>

    <div class="custom-list">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Devir Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
      <template v-slot:item._id="{ item }">
          <router-link :to="`sales/detail/${item._id.$oid}/`">
            <button
            class="btn btn-sm btn-light"
          >
            <i class="flaticon2-next text-dark p-0"></i>
          </button>
          </router-link>
        </template>

        <template v-slot:item.property_name="{ item }">
          <div class="badges-area">
            <div class="badges">
              <i v-if="item.status === '1'" class="badge green"></i>
              <i v-if="item.watch" class="badge yellow"></i>
            </div>
            <p>{{ item.property_name }}</p>
          </div>

        </template>

        <template v-slot:item.status="{ item }">
          <Steps :steps="4" :activeStep="item.status ? item.status : 1" />
        </template>
        <template v-slot:item.tapu="{ item }">
          <div class="tapu-text">
            <h6>{{ item.tapu }}</h6>
            <span
              >tapu: <strong>{{ item.tapuCount }}</strong> | Taşınmaz:
              <strong>{{ item.tasinmazCount }}</strong>
            </span>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PROPERTY_TRANSFER } from "@/core/services/store/transfer/transfer.module";
import { mapGetters } from "vuex";

export default {
  name: "customerList",
  components: {
    Steps: () => import("@/components/global/StepBar"),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Varlık Devir", route: "/transfer/property" },
    ]);
  },
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      header: [
        { text: "", value: "_id", hideMobile:true, size: "50px" },
        { text: "Varlık", value: "property_name" },
        { text: "Banka Gayrimenkul Kodu", value: "bank_propertycode" },
        { text: "İlan Bilgileri", value: "tapu" },
        { text: "Durum", value: "status" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      list_length: 5,
    };
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {
      // this.loading = true;

      // this.totalItems = 3;

      // this.pagination = {
      //   current_page: 1,
      //   total_pages: Math.ceil(this.items?.length / this.list_length),
      //   total_items: this.items.length,
      //   list_length: this.list_length,
      // };
      // this.loading = false;
      // if (this.search) {
      //   const searchValue = this.search;
      //   const foundItems = this.items.filter((item) => {
      //     for (const prop in item) {
      //       if (item[prop].toString().toLowerCase().includes(searchValue)) {
      //         return true;
      //       }
      //     }
      //     return false;
      //   });
      //   this.items = foundItems;
      // }

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
      this.items = [];
      this.totalItems = 0;
      this.loading = true;

      let queryLimit = this.list_length ? this.list_length : 10;
      let query = `transfer/transfer?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&varliktanmi=0`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.status.code == 200) {
            this.$store.dispatch(PROPERTY_TRANSFER, res.response.data);
            this.items = res.response.data;
            this.totalItems = res.response?.data?.length;
            this.pagination = {
              current_page: this.page,
              total_pages: 3,
              total_items: this.totalItems,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });

    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
    list_length: function () {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["getPropertyTransfer", "myUser"]),
  },

};
</script>
